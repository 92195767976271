import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './components/Home';
import Contact from './components/Contact';
import AboutUs from './components/AboutUs';
import Close from './components/Close';
import Faq from './components/Faq';
import Privacy from './components/Privacy';
import AddYourVenue from './components/AddYourVenue';
import AboutUsNew from './components/AboutUsNew';

function App() {
  return <>
      <Router>
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/home" element={<Home />} />
          <Route exact path="/contact" element={<Contact />}/>
          <Route exact path="/aboutus" element={<AboutUsNew />} />
          <Route exact path="/close" element={<Close />} />
          <Route exact path="/faq" element={<Faq />} />
          <Route exact path="/privacy" element={<Privacy />} />
          <Route exact path="addyourvenue" element={<AddYourVenue />} />
        </Routes>
      </Router>
    </>
}
export default App;

