import React from 'react';
import Slide1 from './Slide1';
import Slide2 from './Slide2';
import Slide3 from './Slide3';
import Slide from './Slide'
import pouchHome from '../assets/pouch-home.png';

const colors = ["#F06F56", "#FBB246", "#F0802A"];
const delay = 5000;

const SlideShow = () => {
    
    const [index, setIndex] = React.useState(0);
    const timeoutRef = React.useRef(null);
    console.log(index)
    function resetTimeout() {
        if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
        }
    }

    React.useEffect(() => {
        resetTimeout();
        timeoutRef.current = setTimeout(
        () =>
            setIndex((prevIndex) =>
            prevIndex === colors.length - 1 ? 0 : prevIndex + 1
            ),
        delay
        );

        return () => {
        resetTimeout();
        };
    }, [index]);
    
    return (
        <>
        <div className="slideshow-main">   
            <div className='slideshow-title-box'>
                <div className='slideshow-title-one'>With Pouch, you can{"\u00a0"}
                <div className='slideshow-title-two'>Eat & Earn</div>
                .</div>
            </div>

            <Slide1 color={"#F06F56"} active={index === 0 ? true : false}/>
            <Slide2 color={"#FBB246"} active={index === 1 ? true : false}/>
            <Slide3 color={"#F0802A"} active={index === 2 ? true : false}/>

            {/* <Slide 
                color={"#F06F56"} 
                active={index === 0 ? true : false}
                title="Receive"
                message="Dine at your favourite local cafes and restaurants"
                image={pouchHome}
            />
            <Slide 
                color={"#FBB246"} 
                active={index === 1 ? true : false}
                title="Redeem"
                message="Earn points to redeem complimentary items"
                image={pouchHome}
            />
            <Slide 
                color={"#F0802A"} 
                active={index === 2 ? true : false}
                title="Reward"
                message="Present your Pouch to the server to enjoy your reward"
                image={pouchHome}
            /> */}
            
            <div className='slideshow-dot-box'>
                {colors.map((color, idx) => (
                    <div className='slideshow-dot' key={idx} 
                    style={{backgroundColor: index === idx ? color : "#c4c4c4"}} 
                    onClick={() => {
                        setIndex(idx)
                    }}/>
                ))}
            </div>  
        </div>
        <style jsx>{`
                .slideshow-main {
                    display: flex;
                    flex-direction: column;
                    padding-top: 25px;
                    padding-bottom: 25px;
                    align-items: flex-start;
                    height: 500px;
                    margin-top: 225px;
                }
                .slideshow-title-box {
                    display: flex;
                }
                .slideshow-title-one {
                    display: flex;
                    font-weight: 600;
                    font-size: 36px;
                }
                .slideshow-title-two {
                    display: flex;
                    font-weight: 600;
                    font-size: 36px;
                    color: #F06056;
                }
                .slideshow-dot-box {
                    position: absolute;
                    margin-left: 30px;
                    margin-top: 450px;
                    justify-content: flex-start;
                }
                .slideshow-dot {
                    display: inline-block;
                    height: 20px;
                    width: 20px;
                    border-radius: 50%;
                    cursor: pointer;
                    margin: 15px 15px 0px;
                    background-color: #c4c4c4;
                }

        `}</style>
        </>
        
    );
}

export default SlideShow



