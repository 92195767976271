import React from 'react';
import { HashLink } from "react-router-hash-link";
import logo from '../assets/logo.png';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { FaListUl } from 'react-icons/fa';

const Header = () => {
    const currentPath = useLocation().pathname;
    return (
        <>
        <div className='header-main'>
            <div className='header-container'>
                <HashLink to="/">
                    <img src={logo} style={{width: "75px", height: "75px"}}/>
                </HashLink>

                <div className='header-title-text'> 
                    <HashLink to="/">Pouch Loyalty</HashLink>
                </div>
                <div className='header-tabs'>
                    <div className='header-text-box'>
                        <div className='header-text-inactive' style={{color: currentPath === '/aboutus' ? '#F06B62' : '#D3D3D3' }}>
                            <HashLink to="/aboutus" smooth>About Us</HashLink>
                        </div>
                    </div>
                    <div className='header-text-box'>
                    <div className='header-text-inactive' style={{color: currentPath === '/faq' ? '#F06B62' : '#D3D3D3' }}>
                            <HashLink to="/faq" smooth>FAQ</HashLink>
                        </div>
                    </div>
                    <div className='header-button-box'>
                        <HashLink to="/contact" state={{option: false}}>
                            <div className='header-button-body'>Contact Us</div>
                        </HashLink> 
                    </div>
                </div>
            </div>
        </div>
        <style jsx>{`
				.header-main {
                    display: flex;
                    flex-direction: row;
                    margin-bottom: 60px;
                    position: sticky;
                    width: 100vw;
                    justify-content: center;
                    align-items: center;
                    top: 0;
                    background: rgba( 255,254,251, 0.95 );
                    backdrop-filter: blur( 0px );
                    -webkit-backdrop-filter: blur( 0px );
                    border-radius: 10px;
                    border: 1px solid rgba( 255, 255, 255, 0.18 );
                    z-index: 100;
				}
				.header-container {
                    height: 80px;
                    width: 85vw;
                    display: flex;
                    align-items: center;
                    top: 0;
				}
                .header-logo {
                    cursor: pointer;
                    display: flex;
                }
                .header-title-text {
                    color: #F06B62;
                    cursor: pointer;
                    flex: 1;
                    font-size: 16px;
                    font-weight: 600;
                }
                .header-tabs {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    height: 100%;
                }
                .header-text-box {
                    font-size: 16px;
                    margin: 0px 20px;
                }
                .header-text-inactive {
                    white-space: nowrap;
                    cursor: pointer; 
                    font-weight: 600;
                    transition-duration: 0.3s;
                }
                .header-button-box {
                    margin: 12px;
                }
                .header-button-body {
                    white-space: nowrap;
                    font-weight: 600;
                    padding: 8px;
                    padding-left: 20px;
                    padding-right: 20px;
                    cursor: pointer;
                    background-color: #F06B62;
                    border: none;
                    color: white;
                    border-radius: 500px;
                    transition-duration: 0.3s;
                    font-size: 16px;
                    :hover {
                        filter: saturate(2);
                    }
                }
                @media (max-width: 750px) {
                    .header-title-text {
                        display: none;
                    }
                    .header-text-box {
                        font-size: 14px;
                        margin: 0px 15px;
                    }
                    .header-button-body {
                        font-size: 14px;
                    }
                }
		`}</style>
        </>
    )
}

export default Header

const HeaderMain = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 60px;
    position: sticky;
    top: 0;
    background: rgba( 255,254,251, 0.95 );
    backdrop-filter: blur( 0px );
    -webkit-backdrop-filter: blur( 0px );
    border-radius: 10px;
    border: 1px solid rgba( 255, 255, 255, 0.18 );
    z-index: 100;

    @media (max-width: 1000px) {
        width: 100vw;
    }

`