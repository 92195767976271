import React from 'react'
import styled from 'styled-components';


const Definition = () => {

    return (
        <>
        <div className='definition-main'>
            <div className='definition-title-text'>
                What is Pouch?
            </div>
            <div className='definition-bar'></div>
            <div className='definition-box'>
                <div className='definition-title-box'>
                    <div className='definition-title-row'>
                        <div className='definition-title'>pouch</div>
                        <div className='definition-phonetic'>\ˈpaüch \</div>
                    </div>
                    <div className='definition-small-text'>
                        noun
                    </div>
                </div>
                <div className='definition-list-box'>
                    <div className='definition-row'>
                        <div className='definition-circle-one'>
                            <svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clip-path="url(#clip0_45_144)">
                            <path d="M18.1836 11.55C18.602 14.3008 16.2548 14.607 13.0648 15.0932C9.86782 15.5794 7.03828 16.0623 6.62211 13.3126C6.20157 10.5563 8.44977 7.9335 11.6441 7.44569C14.8351 6.96061 17.7652 8.79483 18.1836 11.55Z" fill="white"/>
                            <path d="M11.825 6.44165C12.9205 6.27479 13.5895 4.70101 13.3193 2.92651C13.049 1.152 11.9418 -0.151254 10.8463 0.0156007C9.75076 0.182456 9.08177 1.75624 9.35204 3.53074C9.62231 5.30525 10.7295 6.6085 11.825 6.44165Z" fill="white"/>
                            <path d="M18.5938 4.46412C18.0737 5.95928 16.9477 6.92888 16.0749 6.62646C15.2043 6.32513 14.9194 4.86553 15.4384 3.37037C15.9573 1.87193 17.0844 0.903962 17.9562 1.2042C18.8268 1.50662 19.1117 2.96513 18.5938 4.46412" fill="white"/>
                            <path d="M7.35417 8.06103C8.14038 7.57979 8.10728 6.09436 7.28025 4.74323C6.45321 3.3921 5.14543 2.68692 4.35922 3.16816C3.57301 3.6494 3.60611 5.13483 4.43314 6.48596C5.26018 7.83709 6.56797 8.54227 7.35417 8.06103Z" fill="white"/>
                            <path d="M5.07943 9.22632C6.04795 10.092 6.42584 11.2503 5.9238 11.8152C5.42068 12.3774 4.22576 12.1335 3.25779 11.2694C2.28763 10.4037 1.91029 9.24601 2.41232 8.68218C2.91654 8.11835 4.11091 8.36171 5.07943 9.22632" fill="white"/>
                            <path d="M16.6709 30.8766C16.2514 33.6274 18.5997 33.9336 21.7902 34.4198C24.9872 34.9065 27.8113 35.3899 28.2329 32.6392C28.6535 29.8829 26.4047 27.2612 23.211 26.7728C20.0194 26.2867 17.0903 28.1225 16.6709 30.8766V30.8766ZM21.5304 22.254C21.2592 24.0275 21.9275 25.6014 23.0239 25.7699C24.1177 25.935 25.2278 24.6324 25.4953 22.8577C25.7665 21.0826 25.0982 19.5092 24.0028 19.343C22.9091 19.1745 21.7989 20.4788 21.5304 22.254ZM16.2569 23.7918C16.777 25.2859 17.903 26.2571 18.7758 25.9542C19.6464 25.6523 19.9314 24.1938 19.4124 22.6981C18.8923 21.2007 17.7663 20.2327 16.8946 20.5324C16.0239 20.8354 15.7368 22.2928 16.2569 23.7918V23.7918ZM27.5734 24.0734C26.7471 25.4231 26.7132 26.9106 27.5012 27.3924C28.2871 27.8731 29.5946 27.1666 30.4204 25.8152C31.2478 24.4639 31.2818 22.9764 30.4948 22.4974C29.7078 22.0161 28.4003 22.7216 27.5734 24.0734" fill="white"/>
                            <path d="M32.4334 28.0064C31.9305 27.4424 30.7368 27.686 29.7672 28.5505C28.7976 29.415 28.4193 30.573 28.9222 31.1371C29.4251 31.7011 30.6188 31.4575 31.5884 30.593C32.558 29.7285 32.9363 28.5704 32.4334 28.0064Z" fill="white"/>
                            </g>
                            <defs>
                            <clipPath id="clip0_45_144">
                            <rect width="35" height="35" fill="white"/>
                            </clipPath>
                            </defs>
                            </svg>
                        </div>
                        <div className='definition-text'>
                            Where baby quokkas, kangaroos, and other marsupials live
                        </div>
                    </div>

                    <div className='definition-row'>
                        <div className='definition-circle-two'>
                            <svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M29.1666 10.2083V7.29167C29.1666 5.68313 27.8585 4.375 26.25 4.375H7.29163C4.87954 4.375 2.91663 6.33792 2.91663 8.75V26.25C2.91663 29.4598 5.53288 30.625 7.29163 30.625H29.1666C30.7752 30.625 32.0833 29.3169 32.0833 27.7083V13.125C32.0833 11.5165 30.7752 10.2083 29.1666 10.2083ZM26.25 23.3333H23.3333V17.5H26.25V23.3333ZM7.29163 10.2083C6.91613 10.1915 6.56159 10.0306 6.30182 9.7589C6.04205 9.48725 5.89707 9.12587 5.89707 8.75C5.89707 8.37413 6.04205 8.01275 6.30182 7.7411C6.56159 7.46945 6.91613 7.30846 7.29163 7.29167H26.25V10.2083H7.29163Z" fill="white"/>
                            </svg>
                        </div>
                        <div className='definition-text'>
                            A digital wallet for loyalty cards from your favorite local restaurants
                        </div>
                    </div>

                    <div className='definition-row'>
                        <div className='definition-circle-one'>
                            <svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M31.5479 9.69338C31.0897 8.63257 30.4291 7.67128 29.603 6.86331C28.7763 6.05293 27.8016 5.40893 26.7319 4.96633C25.6227 4.50557 24.433 4.26972 23.2319 4.27248C21.5469 4.27248 19.9028 4.73391 18.4741 5.60549C18.1323 5.81399 17.8076 6.04299 17.5 6.2925C17.1924 6.04299 16.8677 5.81399 16.5259 5.60549C15.0972 4.73391 13.4531 4.27248 11.7681 4.27248C10.5547 4.27248 9.37891 4.50491 8.26807 4.96633C7.19482 5.41067 6.22754 6.04983 5.39697 6.86331C4.56981 7.67036 3.90906 8.63189 3.45215 9.69338C2.97705 10.7974 2.73438 11.9698 2.73438 13.1763C2.73438 14.3145 2.9668 15.5005 3.42822 16.7071C3.81445 17.7154 4.36816 18.7613 5.07568 19.8174C6.19678 21.4888 7.73828 23.232 9.65234 24.9991C12.8242 27.9282 15.9653 29.9517 16.0986 30.0337L16.9087 30.5532C17.2676 30.7823 17.729 30.7823 18.0879 30.5532L18.8979 30.0337C19.0312 29.9483 22.1689 27.9282 25.3442 24.9991C27.2583 23.232 28.7998 21.4888 29.9209 19.8174C30.6284 18.7613 31.1855 17.7154 31.5684 16.7071C32.0298 15.5005 32.2622 14.3145 32.2622 13.1763C32.2656 11.9698 32.0229 10.7974 31.5479 9.69338Z" fill="white"/>
                            </svg>
                        </div>
                        <div className='definition-text'>
                            A way to say goodbye to those pesky paper cards you keep losing
                        </div>
                    </div>

                </div>
            </div>
        </div>

        <style jsx>{`
                .definition-main {
                    flex: 1;
                    display: flex;
                    flex-direction: column;
                    margin-top: 200px;
                }
                .definition-bar {
                    margin-top: 5px;
                    margin-bottom: 30px;
                    height: 10px;
                    width: 100%;
                    border-radius: 20px;
                    background-image: linear-gradient(90deg, #F06B62, #FFB03A);
                }
                .definition-box {
                    display: flex;
                    flex-direction: row;
                    width: 100%;
                    height: 300px;
                }
                .definition-title-box {
                    display: flex;
                    flex: 1;
                    flex-direction: column;
                    margin-right: 75px;
                }
                .definition-title-row {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    margin-bottom: 15px;   
                }
                .definition-title {
                    font-weight: 600;
                    font-size: 68px;
                    color: #F06056;
                    margin-right: 25px;
                }
                .definition-phonetic {
                    display: flex;
                    font-size: 28px;
                    margin-right: 15px;
                }
                .definition-title-text {
                    font-weight: 600;
                    font-size: 36px;
                }
                .definition-small-text {
                    font-weight: 600;
                    font-size: 36px;
                }
                .definition-list-box {
                    display: flex;
                    flex: 1;
                    flex-direction: column;
                    justify-content: space-between;
                }
                .definition-row {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                }
                .definition-circle-one {
                    display: flex;
                    flex: 0 0 70px;
                    width: 70px;
                    height: 70px;
                    background-color: #F06B62;
                    border-radius: 50%;
                    border: 5px #F5F5F5 solid;
                    justify-content: center;
                    align-items: center;
                }
                .definition-circle-two {
                    display: flex;
                    flex: 0 0 70px;
                    width: 70px;
                    height: 70px;
                    background-color: #FBB246;
                    border-radius: 50%;
                    border: 5px #F5F5F5 solid;
                    justify-content: center;
                    align-items: center;
                }
                .definition-text {
                    display: flex;
                    font-size: 20px;
                    padding-left: 20px;
                }
                @media (max-width: 750px) {
                    .definition-main {
                        width: 100vw;
                        margin-top: 0px;
                    }
                    .definition-title-text {
                        text-align: center;
                    }
                    .definition-small-text {
                        font-size: 28px;
                        padding-bottom: 14px;
                    }
                    .definition-box {
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                    }
                    .definition-title-row {
                        margin-right: 20px;
                    }
                    .definition-title-box {
                        flex-direction: row;
                        justify-content: center;
                        align-items: center;
                        flex: 0;
                        margin-right: 0px;
                    }
                    .definition-title {
                        font-size: 42px;
                    }
                    .definition-phonetic {
                        font-size: 20px;
                    }
                    .definition-text {
                        font-size: 14px;
                    }
                    .definition-circle-one {
                        width: 45px;
                        height: 45px;
                        flex: 0 0 45px;
                        margin-left: 5px;
                    }
                    .definition-circle-two {
                        width: 45px;
                        height: 45px;
                        flex: 0 0 45px;
                        margin-left: 5px;
                    }
                    svg {
                        width: 20px;
                        height: 20px;
                        viewBox: 0 0 35 35;
                    }
                }

		`}</style>
        </>
    )
}

export default Definition
