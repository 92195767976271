import React, {useEffect} from 'react'

const Close = () => {

    useEffect(() => {
        window.close();
    }, [])

    return (
        <div>            
        </div>
    )
}

export default Close
