import React, { useState, useEffect } from 'react'
import Footer from './Footer'
import Header from './Header'
import 'react-phone-number-input/style.css'
import { FaUserAlt } from 'react-icons/fa'
import { MdAddBusiness } from 'react-icons/md'
import { useLocation } from 'react-router-dom'
import styled from 'styled-components'

const Contact = () => {
    const location = useLocation()
    const { option } = location.state
    const [inputs, setInputs] = useState({});
    const [submitted, setSubmitted] = useState(false);
    const [partnerOption, setPartnerOption] = useState(option);

    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setInputs(values => ({...values, [name]: value}))

        if(submitted){
            setSubmitted(false);
        }
    }
    
    
    const submit = (e) => {
        console.log(e)
        e.preventDefault()
        setTimeout(() => {
            setSubmitted(true);
            setInputs({});
        }, 1000);
    }

    useEffect(() => {
        window. scrollTo(0, 0)
    }, [])

    return (
        <>
        <div className='contact-main'>
            <Header />

                <ContactContainer>
                    <div className='contact-left'>
                        <div className='contact-text'>
                            <div className='contact-title'>Get in Touch!</div>
                            <p className='contact-message'>Whether you found a bug, have an idea for a new feature, or just have a question - whatever it is, let us know!</p>
                            <p className='contact-message'><span style={{fontWeight: 700}}>Email</span><br/>pouchloyalty@gmail.com</p>
                            <p className='contact-message'><span style={{fontWeight: 700}}>Location</span><br/>Sydney, Australia</p>
                        </div>
                    </div>
                    
                    <ContactRight>
                        <div className='contact-optionBox'>
                            <div>
                                <p className='contact-input-text' style={{fontWeight: 700}}>Who are you?</p>
                            </div>
                            <div className='contact-optionBox optionBtns'>
                                <button 
                                    className='contact-optionBtn' 
                                    style={{borderColor: !partnerOption ? '#F06B62': '#D3D3D3', color: !partnerOption ? '#F06B62': '#D3D3D3'}}
                                    onClick={() => setPartnerOption(false)}
                                >
                                    <FaUserAlt size={20}/>Normal User
                                </button>
                                <button 
                                    className='contact-optionBtn' 
                                    style={{borderColor: partnerOption ? '#F06B62': '#D3D3D3', color: partnerOption ? '#F06B62': '#D3D3D3'}}
                                    onClick={() => setPartnerOption(true)}
                                >
                                    <MdAddBusiness size={20}/>Business Owner
                                </button>
                            </div>
                        </div>                       
                        <form
                            action="https://formsubmit.co/pouchloyalty@gmail.com" 
                            method="POST"
                            className='contact-detailBox'
                            value="https://pouchloyalty.com/"
                            name="_next"
                        >
                        {
                            !partnerOption ? 
                            <>
                                <div className='contact-input-text'>Contact Information</div>
                                    <input 
                                        value={inputs.contact || ""} 
                                        onChange={handleChange}
                                        name="contact"
                                        className='contact-small-input'
                                        placeholder='Enter your email or phone number'
                                        required
                                    />
                                <div className='contact-input-text'>Nature of Enquiry</div>
                                    <textarea
                                        value={inputs.message || ""} 
                                        onChange={handleChange}
                                        name="message"
                                        className='contact-large-input'
                                        required
                                    />
                            </>
                            : 
                            <>

                                <div className='contact-input-text'>Contact Information</div>
                                    <input 
                                        value={inputs.contact || ""} 
                                        onChange={handleChange}
                                        name="contact"
                                        className='contact-small-input'
                                        placeholder='Enter your email or phone number'
                                        required
                                    />
                                <div className='contact-input-text'>Business Name</div>
                                    <input
                                        type="text"
                                        value={inputs.nature || ""} 
                                        onChange={handleChange}
                                        name="nature"
                                        className='contact-small-input'
                                        placeholder='Enter your business name'
                                    />
                                <div className='contact-input-text'>Nature of Enquiry</div>
                                    <textarea
                                        value={inputs.message || ""} 
                                        onChange={handleChange}
                                        name="message"
                                        className='contact-large-input'
                                        required
                                    />
                            </>
                        }
                            <input type="submit" value="Submit" className='contact-submit'/>
                        </form>
                        {
                            submitted && <>
                                <div className='contact-submitted'>
                                    Thank you for your input!
                                </div>
                            </>
                        } 
                    </ContactRight>
            </ContactContainer>
            <Footer />
        </div>

        <style jsx>{`
        .contact-main {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
        }
        .contact-container {
            width: 1200px;
            justify-content: space-between;
            align-items: flex-start;
            display: flex;
            flex-direction: row;
        }
        .contact-left {
            display: flex;
            flex: 1;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            margin: 20px;
        }
        .required {
            flex-direction: column;
            align-items: flex-start;
        }
        .contact-title {
            font-size: 42px;
            font-weight: 600;
            background-image: linear-gradient(180deg, #F06B62, #FBB246);
            background-clip: text;
            -webkit-background-clip: text;
            color: transparent;
            margin-bottom: 40px;
        }
        .contact-right {
            display: flex;
            flex: 1;
            flex-direction: column;
            padding: 40px;
            box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
            border-radius: 20px;
        }
        .contact-right:hover{
            box-shadow: rgba(255,182,193, 0.2) 0px 4px 12px;
        }
        .contact-input-text {
            font-size: 16px;
            font-weight: 600;
            margin: 10px 0px;
        }
        .contact-small-input {
            height: 40px;
            font-size: 14px;
            font-family: "Raleway";
            background-color: transparent;
            border: 1.5px #D3D3D3 solid;
            border-radius: 20px;
            margin-bottom: 10px;
            padding-left: 20px;
        }
        .contact-large-input {
            font-size: 16px;
            font-family: "Raleway";
            background-color: transparent;
            border: 1.5px #D3D3D3 solid;
            border-radius: 20px;
            margin-bottom: 10px;
            padding: 10px 20px;
            height: 200px;
            resize: none;
        }
        .contact-submit {
            width: 200px;
            height: 40px;
            font-size: 20px;
            display: flex;
            border: none;
            border-radius: 40px;
            justify-content: center;
            background: #F06B62;
            color: white;
        }
        .contact-submitted {
            font-size: 18px;
            font-weight: 600;
            margin-top: 30px;
            display: flex;
            justify-content: center;
        }
        .contact-detailBox, .contact-detailBox, .contact-optionBox {
            flex: 1;
            display: flex;
            flex-direction: column;
        }
        .contact-detailBox {
            margin: 20px 0px;
        }
        .contact-optionBtn {
            width: 270px;
            border-radius: 20px;
            border: 1px solid;
            background: transparent;
            display: flex;
            justify-content: space-evenly;
            align-items: center;
            padding: 10px 80px 10px 0px;
            margin: 10px;
        }
        .contact-optionBtn:hover, .contact-submit:hover{
            cursor: pointer;
            transform: translate(0px, -2px);
        } 
        .optionBtns {
            flex-direction: row;
            justify-content: space-between;
        }
        `}</style>
        </>
    )
}

export default Contact

const ContactContainer = styled.div`
    width: 1200px;
    justify-content: space-between;
    align-items: flex-start;
    display: flex;
    flex-direction: row;

    @media (max-width: 1000px) {
        width: 100%;
        flex-direction: column;
    }
`

const ContactRight = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    padding: 40px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
    border-radius: 20px;

    &:hover { 
        box-shadow: rgba(255,182,193, 0.2) 0px 4px 12px;
    }

    @media (max-width: 1000px) {
        display: none;
    }
`