import React from 'react'
import landing from '../assets/landing.png';
import styled from 'styled-components';


const Landing = () => {
    return (
        <>
        <div className='landing-main'>  
            <div className='landing-title-box'>
                    <div className='landing-top'>Simplifying</div>
                    <div className='landing-middle'>Loyalty Cards</div>
                    <div className='landing-bottom'>Boost customer retention<br/>Grow your business<br/>And help save the environment while you're at it</div>
            </div>
            <div className='landing-image'> 
                <img src={landing} style={{width: '90%'}}/>
            </div>
        </div>
        <style jsx>{`
                .landing-main {
                    display: flex;
                    height: 600px;
                    justify-content: space-between;
                    align-items: center;
                }
                .landing-title-box {
                    display: flex;
                    margin: 20px;
                    flex-direction: column;
                }
                .landing-top {
                    font-weight: 600;
                    font-size: 76px;  
                }
                .landing-middle {
                    font-weight: 600;
                    background-image: linear-gradient(180deg, #E21143, #FFB03A);
                    background-clip: text;
                    -webkit-background-clip: text;
                    color: transparent;
                    font-size: 76px;
                    margin-bottom: 25px;
                }
                .landing-bottom {
                    font-weight: 400;
                    font-size: 23px; 
                }
                .landing-image {
                    height: 500px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
                @media (max-width: 750px) {
                    .landing-main {
                        flex-direction: column;
                        height: 700px;
                        justify-content: center;
                    }
                    .landing-title-box {
                        justify-content: center;
                        align-items: center;
                    }
                    .landing-top {
                        font-size: 38px;
                    }
                    .landing-middle {
                        font-size: 38px;
                    }
                    .landing-bottom {
                        font-size: 20px;
                        text-align: center;
                    }
                    .landing-image {
                        height: 400px;
                        width: 300px;
                        margin-bottom: 50px;
                    }
                }
        `}</style>
        </>
    )
}

export default Landing