import React from 'react'
import miyama from '../assets/miyama.png'
import charles from '../assets/charles.png'
import twelve from '../assets/twelve.png'
import bamboo from '../assets/bamboo.png'
import newtown from '../assets/newtownthai.png'
import ohmydays from '../assets/ohmydays.png'
import barmuda from '../assets/barmuda.png'
import styled from 'styled-components'

const Partners = () => {

    return (
        <>
        <div className='partners-main'>
            <PartnerTitleBox>
                <p className='partners-title-one'>
                    Check out our growing list of{"\u00a0"} 
                    <span className='partners-title-two'>partners</span>
                    !
                </p>
            </PartnerTitleBox>
            <PartnerImageBox>
                <a href='https://miyama.com.au/'>
                    <img className='partners-image' src={miyama} alt="Miyama's logo"/>
                </a>
                <a href='https://charlesthainewtown.com.au/'>
                    <img className='partners-image' src={charles} alt="Charles Thai's logo"/>
                </a>
                <a href='https://www.facebook.com/thetwelvecafe'>
                    <img className='partners-image' src={twelve} alt="Twelve Cafe's logo"/>
                </a>
                <a href='https://bamboohutfusion.lifeintouch.net/'>
                    <img className='partners-image' src={bamboo} alt="Bamboo Hut's logo"/>
                </a>
            </PartnerImageBox>
            <PartnerImageBox>
                <a href='https://www.facebook.com/profile.php?id=100063180733776'>
                    <img className='partners-image' src={newtown} alt="Newtown Thai's logo"/>
                </a>
                <a href='https://www.ohmydays.com.au/'>
                    <img className='partners-image' src={ohmydays} alt="Oh My Days' logo"/>
                </a>
                <a href='https://www.facebook.com/cafebarmuda/'>
                    <img className='partners-image' src={barmuda} alt="Barmuda's logo"/>
                </a>
            </PartnerImageBox>
        

        </div>
    
        <style jsx>{`
            .partners-main {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                align-items: center;
                margin-top: 100px;
            }
            .partners-title-box {
                display: flex;
                margin-bottom: 50px;
            }
            .partners-title-one {
                font-weight: 600;
                font-size: 36px;
            }
            .partners-title-two {
                font-weight: 600;
                font-size: 36px;
                color: #F06056;
            }
            .partners-image {
                cursor: pointer;
                border-radius: 50%;
                height: 150px;
                width: 150px;
                box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
                object-fit: cover;
                margin-left: 40px;
                margin-right: 40px;
            }
        `}</style>
        </>
    )
}

export default Partners

const PartnerImageBox = styled.div`
    display: flex;
    flex-direction: row;
    width: 1000px;
    justify-content: center;

    @media (max-width: 1000px) {
        flex-wrap: wrap;
        width: 100vw;
        justify-content: space-evenly;
    }

`

const PartnerTitleBox = styled.div`
    display: flex;
    margin-bottom: 50px;
    text-align: center;

    @media (max-width: 1000px) {
        flex-wrap: wrap;
        width: 100vw;
    }
`