import React from 'react'
import apple from '../assets/appstore.png'
import google from '../assets/googleplay.png'
import styled from 'styled-components'
import downloadApple from '../assets/downloadapple.png'
import downloadGoogle from '../assets/downloadgoogle.png'


const Info = () => {

    return (
        <>
        <div className='info-main'>
            <div className='info-title-box'>
                <div className='info-title-one'>Scan the code on your phone and download{"\u00a0"} <span className='info-title-two'>Pouch Loyalty</span>{"\u00a0"}today!</div>
            </div>
            <div className='info-qr-box'>
                <div className='info-qr'>
                    <img src={apple} style={{height: '130px', width: '130px', boxShadow: '0px 0px 10px 1px grey'}}/>
                    <img src={downloadApple} style={{height: '50px', width: '160px', marginTop: '20px'}}/>
                </div>

                <div className='info-qr'>
                    <img src={google} style={{height: '130px', width: '130px', boxShadow: '0px 0px 10px 1px grey'}}/>
                    <img src={downloadGoogle} style={{height: '50px', width: '160px', marginTop: '20px'}}/>
                </div>
            </div>
        </div>
        <style jsx>{`
            .info-main {
                width: 900px;
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                margin-top: 150px;
            }
            .info-qr-box {
                display: flex;
                flex-direction: row;
                height: 200px;
                justify-content: center;
            }
            .info-qr {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                margin: 0px 10px;
            }
            .info-title-box {
                display: flex;
                justify-content: center;
                padding: 40px;
            }
            .info-title-one {
                display: flex;
                flex-wrap: wrap;
                font-weight: 600;
                font-size: 36px;
            }
            .info-title-two {
                background-image: linear-gradient(180deg, #F74F2D, #FBB246);
                background-clip: text;
                -webkit-background-clip: text;
                color: transparent;
            }
            @media (max-width: 750px) {
                .info-main {
                    flex-direction: column;
                    width: 100vw;
                    height: 400px;
                }
            }

        `}</style>
        </>
    )
}

export default Info