import React from 'react'
import { HashLink } from "react-router-hash-link";
import ReactTooltip from 'react-tooltip';
import Contact from './Contact.js'

const Footer = () => {
    return (
        <>
        <div className='footer-main'>
            <div className='footer-bar'></div>
            <div className='footer-top'>
                <div className='footer-col'>Join Pouch
                    <div className='footer-col-item'>
                        <HashLink to="/contact" state={{option: true}}><div className='footer-text'>Merchant</div></HashLink> <br />
                        <HashLink to="/contact" state={{option: false}}><div className='footer-text'>User</div></HashLink>
                    </div>
                </div>
                <div className='footer-col'>Discover
                    <div className='footer-col-item'>
                        <HashLink to="/aboutus"><div className='footer-text'>Mission</div></HashLink> <br />
                        <HashLink to="/#hiw"><div className='footer-text'>How Pouch Works</div></HashLink> <br />
                    </div>
                </div>
                <div className='footer-col'>About
                    <div className='footer-col-item'>
                        <HashLink to="/aboutus"><div className='footer-text'>Team</div></HashLink> <br />
                        <HashLink to="/faq"><div className='footer-text'>FAQ</div></HashLink> <br />
                    </div>
                </div>
                <div className='footer-col'>Contact Us
                    <div className='footer-col-item'>
                        <div className='footer-text' data-tip="pouchloyalty@gmail.com">Email</div> <br />
                        <ReactTooltip 
                            backgroundColor="#F06B62"
                            borderColor='white'
                            border={true}
                        />
                    </div>
                </div>

            </div>
            <div className='footer-bottom'>
                Powered By Alrighty | ABN 70 899 637 807 | All Rights Reserved
            </div>
        </div>
        <style jsx>{`
				.footer-main {
					width: 100%;
                    height: 200px;
                    background-color: rgba(255,255,255,0.9);
                    display: flex;
                    flex-direction: column;
                    margin-top: 100px;
				}
                .footer-bar {
                    height: 10px;
                    width: 100%;
                    border-radius: 20px;
                    background-image: linear-gradient(90deg, #F06B62, #FFB03A);
                }
				.footer-top {
					flex: 7;
                    width: 100%;
                    display: flex;
                    justify-content: center;
                    flex-wrap: wrap;
                    color: darkgray;
				}
                .footer-col {
                    margin: 10px;
                    margin-top: 20px;
                    font-weight: 700;
                    font-size: 1.25em;
                    min-width: 200px;
                    text-align: center;
                    color: gray;
                }
                .footer-col-item {
                    margin-top: 10px;
                    font-weight: 400;
                    font-size: 0.75em;
                    line-height: 1;
                    color: gray;
                    cursor: pointer;
                }
                .footer-text {
                    cursor: pointer;
                    transition-duration: 0.3s;
                    :hover {
                        color: darkgray;
                    }
                }
                .footer-bottom {
                    flex: 3;
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: 0.75em;
                    min-height: 40px;
                    color: gray;
                }
		`}</style>
        </>
    )
}

export default Footer