import React from 'react';
import redeem from '../assets/redeem.png';

const Slide2 = (props) => {

    return (
        <>
        <div className='slide2-main'>
            <div className='slide2-outer'>
                <div className='slide2-box'>
                    <div className='slide2-textbox'>
                        <div className='slide2-title'>
                            Redeem
                        </div>
                        <div className='slide2-description'>
                            Earn points to redeem complimentary items 
                        </div>
                    </div>
                </div>
            </div>
            
            <div className='slide2-image-box'>
                <img src={redeem} style={{height: "80%"}} alt="Image2"/>
            </div>
            
        </div>

        <style jsx>{`
                .slide2-main {
                    display: flex;
                    width: 1000px;
                    flex-direction: row;
                    align-items: center;
                    position: absolute;
                    opacity: ${props.active ? 1 : 0};
                    transition: opacity 0.5s ease-in;
                }
                .slide2-outer {
                    display: flex;
                    flex-direction: column;
                }
                .slide2-box {
                    display: flex;
                    flex-direction: row;
                    height: 250px;
                    width: 1100px;
                    border-radius: 200px;
                    color: white;
                    background-color: ${props.color};
                    justify-content: center;
                    align-items: center;    
                }
                .slide2-textbox {
                    padding-top: 50px;
                    padding-bottom: 50px;
                    padding-left: 120px;
                    padding-right: 300px;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                }
                .slide2-title {
                    font-weight: 600;
                    font-size: 48px;
                    color: #fffefb;
                    margin-bottom: 10px;
                }
                .slide2-description {
                    font-size: 36px;
                    color: #fffefb;
                }
                .slide2-image-box {
                    transform: translateX(-300px);
                    width: 450px;
                    height: 600px;
                    z-index: 1;
                }
            `}</style>
        </>
    )
}

export default Slide2;