import React, { useEffect } from 'react';
import Footer from './Footer';
import Header from './Header';
import { HashLink } from "react-router-hash-link";
import Collapsible from 'react-collapsible';
import heart from '../assets/heart.png';

const Faq = () => {
    useEffect(() => {
        window. scrollTo(0, 0)
    }, [])
    return (
        <>
            <div className='faq-main'>
                <Header/>
                <div className='faq-title'>
                    Have a question?
                </div>
                <div className='faq-text'>
                    Check to see if we’ve answered it below. If not, head to 'Contact Us', fill in the form, and we’ll respond as soon as we can.
                </div>

                <Collapsible trigger={["How do I earn loyalty points?", <img src={heart} style={{height: "30px", width: "30px", marginRight: "30px"}}/>]}>
                    <div className='faq-answer'>
                        Earning points is easy - all you need to do is show your QR code. Check out a short demo{"\u00a0"}
                        <div className='faq-navigation'>
                            <HashLink to="/#slideshow">here!</HashLink>
                        </div>
                    </div>
                </Collapsible>

                <Collapsible trigger={["How many points do I need before redeeming a reward?", <img src={heart} style={{height: "30px", width: "30px", marginRight: "30px"}}/>]}>
                    <div className='faq-answer'>
                        Each of our partners sets their own loyalty program with regard to accuring points and redeeming rewards. Download Pouch Loyalty to learn more!
                    </div>
                </Collapsible>

                <Collapsible trigger={["How do I download Pouch Loyalty?", <img src={heart} style={{height: "30px", width: "30px", marginRight: "30px"}}/>]}>
                    <div className='faq-answer'>
                        Download Pouch Loyalty from the App Store or Google Play Store via QR codes{"\u00a0"}
                        <div className='faq-navigation'>
                            <HashLink to="/#info">here!</HashLink>
                        </div>
                    </div>
                </Collapsible>

                <Collapsible trigger={["Where can I use Pouch Loyalty?", <img src={heart} style={{height: "30px", width: "30px", marginRight: "30px"}}/>]}>
                    <div className='faq-answer'>
                        You can find a full list of our partners{"\u00a0"}
                        <div className='faq-navigation'>
                            <HashLink to="/#partners">here!</HashLink>
                        </div>
                        We’re constantly onboarding new partners so keep your eyes peeled!
                    </div>
                </Collapsible>

                <Collapsible trigger={["I'm having problems with my Pouch Loyalty app, what should I do?", <img src={heart} style={{height: "30px", width: "30px", marginRight: "30px"}}/>]}>
                    <div className='faq-answer'>
                        We are always trying to improve the Pouch Loyalty experience. Shoot us a message{"\u00a0"}
                        <div className='faq-navigation'>
                            <HashLink to="/contact" state={{option: false}}>here!</HashLink>
                        </div>
                    </div>
                </Collapsible>

                <Collapsible trigger={["How much does Pouch Loyalty cost?", <img src={heart} style={{height: "30px", width: "30px", marginRight: "30px"}}/>]}>
                    <div className='faq-answer'>
                        Pouch Loyalty is currently in public beta testing and will remain completely free for all partners until June 30, 2023. It will always be free for users!
                    </div>
                </Collapsible>

                <Collapsible trigger={["I want to partner with Pouch Loyalty, how do I sign up?", <img src={heart} style={{height: "30px", width: "30px", marginRight: "30px"}}/>]}>
                    <div className='faq-answer'>
                        You can fill in your details{"\u00a0"}
                        <div className='faq-navigation'>
                            <HashLink to="/contact" state={{option: true}}>here!</HashLink>
                        </div>
                        After that, the Pouch team will reach out to you and begin the formal onboarding process.
                    </div>
                </Collapsible>
                <Footer />
            </div>
            
        <style jsx>{`
            .faq-main {
                display: flex;
                justify-content: center;
                align-items: center;
                overflow: hidden;
                flex-direction: column;
                width: 100%;
                margin-bottom: 30px;
            }
            .faq-title {
                font-size: 42px;
                font-weight: 600;
                background-image: linear-gradient(180deg, #F06B62, #FBB246);
                background-clip: text;
                -webkit-background-clip: text;
                color: transparent;
                margin-bottom: 30px;
            }
            .faq-text {
                width: 50%;
                text-align: center;
                font-size: 20px;
                margin-bottom: 30px;
            }
            .faq-answer {
                padding: 20px 80px 40px 20px;
                color: #000000;
                font-size: 20px;
                display: flex;
                flex-direction: row;
                align-items: baseline;
                flex-wrap: wrap;
            }
            .faq-navigation {
                font-weight: 600;
                cursor: pointer;
            }
        `}</style>
        </>
    )
}

export default Faq