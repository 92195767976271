import React from 'react'
import styled from 'styled-components'
import {GiBarrier} from 'react-icons/gi';
import { IconContext } from 'react-icons/lib';

const UnderConstruction = () => {
    return (
        <Container>
            <Inner>

                <IconContext.Provider value={{ color: 'gray', size: '50px' }}>
                    <div style={{paddingRight: "20px"}}> <GiBarrier /></div>
                </IconContext.Provider>
                Under construction, check back soon!
            </Inner>

        </Container>
    )
}

export default UnderConstruction

const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60vh;
`

const Inner = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    color: gray;
    font-size: 1.5em;
    font-weight: 500;
    min-width: 350px;
    flex-wrap: wrap;
    padding: 20px;
`