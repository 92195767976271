import React, { useEffect } from 'react'
import Footer from './Footer'
import Header from './Header'
import idea from '../assets/ideaphoto.png';
import george from '../assets/georgephoto.png';
import q from '../assets/qphoto.png';
import cm from '../assets/cmphoto.png';
import { AiFillHeart } from 'react-icons/ai';
import { FaLightbulb, FaHandshake, FaExchangeAlt } from 'react-icons/fa';
import styled from 'styled-components';

var values = [
    {
        name: 'Partnership',
        description: 'We value genuine partnership. We’re here to support your business. If you have any ideas, questions, or concerns, let us know and we’ll see what we can do!',
        icon: <AiFillHeart color='white' size={30}/>
    },
    {
        name: 'Innovation',
        description: 'Pouch will always be a work in progress. We’re always thinking about what we can do better. As we grow, so will the platform. Stay tuned for feature releases!',
        icon: <FaLightbulb color='white' size={30}/>
    },
    {
        name: 'Integrity',
        description: 'We earn the trust of our partners and users. Your data stays with us. We promise to be open, honest, and transparent - we hope you’ll do the same.',
        icon: <FaHandshake color='white' size={30}/>
    },
    {
        name: 'Change',
        description: '“Be the change you wish to see in this world” We agree. Pouch will always be about more than just the bottom line.',
        icon: <FaExchangeAlt color='white' size={30}/>
    },
]

const AboutUsNew = () => {
    useEffect(() => {
        window. scrollTo(0, 0)
    }, [])

    return <>
        <div className='aboutus-main'>
            <Header />
            <AboutUsContainer>
                <div className='aboutus-intro'>
                    <p className='aboutus-descrip'>Who are we?</p>
                    <div className='aboutus-title-one'>
                        Our mission is simple, to make loyalty programs
                    </div>
                    <div className='aboutus-title-two'>
                        as easy as possible.
                    </div>
                    <p className='aboutus-text'>Restauraunts love rewarding loyal customers for their support.<br/>And customers, well, they love rewards - especially if it’s from their go-to lunch spot or favorite café.</p>
                    <p className='aboutus-text'>
                        But have you ever collected 6 stamps just to start from 0 again because you lost your card? Or purchased a new minimalist wallet and realized you don’t quite have the space to fit them all anymore? Or thought to yourself, I don’t even carry a wallet anymore, where I would even keep this? Because we have, and we hated it. 
                    </p>
                    <p className='aboutus-text text-bold'>That’s why we built Pouch. </p>
                </div>
                <div className='aboutus-valueContainer'>
                    {
                        values.map(val => <div className='aboutus-valueBox'>
                            <div className='valueBox-logo'>
                                <div className='logoBox'>{val.icon}</div>
                            </div>
                            <div className='valueBox-header'>{val.name}</div>
                            <div className='valueBox-description'>{val.description}</div>

                        </div>)
                    }
                </div>

                <AboutUsTeamRow>
                    <a href='https://www.linkedin.com/in/phasavee-idea-vanicharoenchai-b40a5b1a4/' className='aboutus-team-box'>
                        <div className='aboutus-team-below'>
                            <img alt="idea" src={idea} style={{ height: '100%', width:"100%", objectFit: 'cover', borderRadius: '20px'}}/>
                        </div>
                        <div className='aboutus-team-above'>
                            <p className='aboutus-intro-text'>Idea</p>
                            <p className='aboutus-job-title'>Product Developer</p>
                        </div>
                    </a>
                    <a href='https://www.linkedin.com/in/george-mukun-wang/' className='aboutus-team-box'>
                        <div className='aboutus-team-below'>
                            <img alt="george" src={george} style={{ height: '100%', width:"100%", objectFit: 'cover', borderRadius: '20px'}}/>
                        </div>
                        <div className='aboutus-team-above'>
                            <p className='aboutus-intro-text'>George</p>
                            <p className='aboutus-job-title'>Product Developer</p>

                        </div>
                    </a>
                
                    <a href='https://www.linkedin.com/in/phasith-vanicharoenchai-79106315a/' className='aboutus-team-box'>
                        <div className='aboutus-team-below'>
                            <img alt="q" src={q} style={{ height: '100%', width:"100%", objectFit: 'cover', borderRadius: '20px'}}/>
                        </div>
                        <div className='aboutus-team-above'>
                            <p className='aboutus-intro-text'>Q</p>
                            <p className='aboutus-job-title'>Sales & Marketing</p>
                        </div>
                    </a>
                    <a href='https://www.linkedin.com/in/phasith-vanicharoenchai-79106315a/' className='aboutus-team-box'>
                        <div className='aboutus-team-below'>
                            <img alt="cm" src={cm} style={{ height: '100%', width:"100%", objectFit: 'cover', borderRadius: '20px'}}/>
                        </div>
                        <div className='aboutus-team-above'>
                            <p className='aboutus-intro-text'>CM</p>
                            <p className='aboutus-job-title'>Sales & Marketing</p>
                        </div>
                    </a>
                </AboutUsTeamRow>
            </AboutUsContainer>
            <Footer />
        </div>
        <style jsx>{`
            .aboutus-main {
                display: flex;
                width: 100vw;
                flex-direction: column;
                align-items: center;
                justify-content: center;
            }
            .aboutus-title-one, .aboutus-title-two, .aboutus-descrip  {
                color: #F06B62;
                flex: 1;
                font-size: 36px;
                font-weight: 600;
            }
            .aboutus-title-two {
                color: #FBB246;
            }
            .aboutus-descrip{
                font-size: 18px;
            }
            .aboutus-text {
                line-height: 25px;
                font-size: 16px;
                margin: 35px 0px;
            }
            .text-bold {
                font-weight: bold;
            }
            .aboutus-team-row {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
            }
            .aboutus-team-box {
                width: 250px;
                height: 400px;
                display: flex;
                flex-direction: column;
                position: relative;
                border-radius: 30px;
            }
            .aboutus-team-box:hover{
                box-shadow: rgba(255,140,105, 0.4) 0px 7px 29px 0px;
                transform: translateY(-5px);
                transition: linear 0.2s;
                cursor: pointer;
            }

            .aboutus-team-below {
                flex: 1;
                display: flex;
                flex-direction: column;
                align-items: center;
                border-radius: 30px;
            }

            .aboutus-team-above {
                display: flex;
                flex-direction: column;
                z-index: 5;
                position: absolute;
                background-color: rgba(0,0,0, 0.40);
                width: 100%;
                height: 100%;
                justify-content: flex-end;
                border-radius: 20px;
            }
            .aboutus-intro {
                margin: 20px;
                text-align: center;
                padding: 0px 120px;

            }

            .aboutus-intro-text {
                font-size: 28px;
                margin: 5px 0px;
                color: white;
                margin: 0px 20px;
                font-weight: 600;
            }

            .aboutus-job-title {
                font-size: 20px;
                margin: 0px 20px 20px;
                color: white;
            }
            .aboutus-valueBox {
                width: 500px;
                height: 200px;
                margin: 20px;
                border-radius: 20px;
                box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
                padding: 20px;
            }
            .aboutus-valueContainer {
                display: flex;
                flex-wrap: wrap;
                justify-content: flex-start;
                margin: 40px 0px 80px;
            }
            .valueBox-header, .valueBox-description, .valueBox-logo{
                flex: 1;
                margin: 15px 0px;
            }
            .valueBox-header {
                font-size: 24px;
                font-weight: 700;
            }
            .logoBox {
                width: 50px;
                height: 50px;
                border-radius: 10px;
                background-color: #F06B61;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        `}</style>
        </>
}

export default AboutUsNew

const AboutUsContainer = styled.div`
    width: 1200px;
    display: flex;
    flex-direction: column;

    @media (max-width: 1000px) {
        width: 100%;
    }
`

const AboutUsTeamRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    
    @media (max-width: 1000px) {
        flex-direction: column;
        height: 1800px;
    }
`