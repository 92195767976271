import React from 'react'
import Footer from './Footer'
import Header from './Header'
import UnderConstruction from './UnderConstruction'
import styled from 'styled-components'
import idea from '../assets/ideaphoto.png';
import george from '../assets/georgephoto.png';
import q from '../assets/qphoto.png';
import cm from '../assets/cmphoto.png';


const AboutUs = () => {
    return <>
        <Header />
        <div className='aboutus-main'>
            <div className='aboutus-title-box'>
                <div className='aboutus-title-one'>
                    Our mission is simple - make loyalty programs
                </div>
                <div className='aboutus-title-two'>
                    as easy as possible.
                </div>
                <div className='aboutus-message'>
                    Businesses love rewarding loyal customers for their support. And customers, well, they love rewards -
                    especially if it's from their go-to lunch spot or favourite café.<br/><br/>
                    But have you ever collected 9 stamps on a loyalty card, 1 away from redeeming a free meal, 
                    just to start from 0 again because you lost your card? Or have you purchased a new minimalist 
                    wallet that cannot fit all of cards? Or have you thought to yourself, 
                    <div className='aboutus-message-italic'>
                        I don't even carry a wallet, where would I even keep this new card?
                    </div><br/><br/>
                    Because we have, and we hated it. <br/><br/>
                    <div className='aboutus-message-bold'>
                        That's why we built Pouch.
                    </div>
                </div>
            </div>
            
            <div className='aboutus-team-container'>
                <div className='aboutus-title-three'>Our Team</div>
                <div className='aboutus-team-row'>
                    <a href='https://www.linkedin.com/in/phasavee-idea-vanicharoenchai-b40a5b1a4/' className='aboutus-team-box'>
                        <div className='aboutus-team-below'>
                            <img alt="idea" src={idea} style={{ height: '100%', width:"100%", objectFit: 'cover', borderRadius: '20px'}}/>
                        </div>
                        <div className='aboutus-team-above'>
                            <p className='aboutus-intro-text'>Idea</p>
                            <p className='aboutus-job-title'>Product Developer</p>
                        </div>
                    </a>
                    <a href='https://www.linkedin.com/in/george-mukun-wang-630122186/' className='aboutus-team-box'>
                        <div className='aboutus-team-below'>
                            <img alt="george" src={george} style={{ height: '100%', width:"100%", objectFit: 'cover', borderRadius: '20px'}}/>
                        </div>
                        <div className='aboutus-team-above'>
                            <p className='aboutus-intro-text'>George</p>
                            <p className='aboutus-job-title'>Product Developer</p>

                        </div>
                    </a>
                
                    <a href='https://www.linkedin.com/in/phasith-vanicharoenchai-79106315a/' className='aboutus-team-box'>
                        <div className='aboutus-team-below'>
                            <img alt="q" src={q} style={{ height: '100%', width:"100%", objectFit: 'cover', borderRadius: '20px'}}/>
                        </div>
                        <div className='aboutus-team-above'>
                            <p className='aboutus-intro-text'>Q</p>
                            <p className='aboutus-job-title'>Sales & Marketing</p>
                        </div>
                    </a>
                    <a href='https://www.linkedin.com/in/phasith-vanicharoenchai-79106315a/' className='aboutus-team-box'>
                        <div className='aboutus-team-below'>
                            <img alt="cm" src={cm} style={{ height: '100%', width:"100%", objectFit: 'cover', borderRadius: '20px'}}/>
                        </div>
                        <div className='aboutus-team-above'>
                            <p className='aboutus-intro-text'>CM</p>
                            <p className='aboutus-job-title'>Sales & Marketing</p>
                        </div>
                    </a>
                </div>
            </div>
            <div className='aboutus-title-three'>Our Values</div>
            <div className='aboutus-values-main'>
                <div className='aboutus-values-row'>
                    <div className='aboutus-values-box'>
                        <div className='aboutus-values-icon'></div>
                        <div className='aboutus-values-title'>
                            Partnership
                        </div>
                        <div className='aboutus-values-text'>
                        We value genuine partnership. We’re here to support your business. If you 
                        have any ideas, questions, or concerns, let us know and we’ll see what we can do!
                        </div>
                    </div>
                    <div className='aboutus-values-box'>
                        <div className='aboutus-values-icon'></div>
                        <div className='aboutus-values-title'>
                            Innovation
                        </div>
                        <div className='aboutus-values-text'>
                        Pouch will always be a work in progress. We’re always thinking about what we can 
                        do better. As we grow, so will the platform. Stay tuned for feature releases!
                        </div>
                    </div>
                </div>
                <div className='aboutus-values-row'>
                    <div className='aboutus-values-box'>
                        <div className='aboutus-values-icon'></div>
                        <div className='aboutus-values-title'>
                            Integrity
                        </div>
                        <div className='aboutus-values-text'>
                        We earn the trust of our partners and users. Your data stays with us. 
                        We promise to be open, honest, and transparent - we hope you’ll do the same.
                        </div>
                    </div>
                    <div className='aboutus-values-box'>
                        <div className='aboutus-values-icon'></div>
                        <div className='aboutus-values-title'>
                            Change
                        </div>
                        <div className='aboutus-values-text'>
                            <div className='aboutus-values-italic'>
                                “Be the change you wish to see in this world”
                            </div>
                            <br/>
                        We agree. Pouch will always be about more than just the bottom line.
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
        <style jsx>{`
            .aboutus-main {
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
            }
            .aboutus-container {
                padding-top: 50px;
                padding-bottom: 50px;
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
            }
            .aboutus-title-box {
                width: 60%;
                display: flex;
                flex-direction: column;
                align-items: center;
            }
            .aboutus-title-one {
                color: #F06B62;
                flex: 1;
                font-size: 36px;
                font-weight: 600;
            }
            .aboutus-title-two {
                color: #FBB246;
                flex: 1;
                font-size: 36px;
                font-weight: 600;
                margin-bottom: 30px;
            }
            .aboutus-message {
                text-align: center;
                font-size: 24px;
            }
            .aboutus-message-italic {
                font-style: italic;
            }
            .aboutus-message-bold {
                font-weight: 600;
            }
            .aboutus-title-three {
                color: #F06B62;
                font-weight: 600;
                font-size: 36px;
                margin-top: 50px;
            }
            .aboutus-team-container {
                margin-top: 80px;
                margin-bottom: 80px;
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
            }
            .aboutus-team-row {
                width: 60%;
                display: flex;
                flex-direction: row;
                justify-content: space-around;
                align-items: center;
                margin-top: 60px;
            }
            .aboutus-team-box {
                width: 200px;
                height: 350px;
                display: flex;
                min-height: 150px;
                flex-direction: column;
                z-index: 0;
                position: relative;
                border-radius: 30px;
            }
            .aboutus-team-box:hover{
                box-shadow: rgba(255,140,105, 0.4) 0px 7px 29px 0px;
                transform: translateY(-5px);
                transition: linear 0.2s;
                cursor: pointer;
            }

            .aboutus-team-below {
                flex: 1;
                display: flex;
                flex-direction: column;
                align-items: center;
                border-radius: 30px;
            }

            .aboutus-team-above {
                display: flex;
                flex-direction: column;
                z-index: 5;
                position: absolute;
                background-color: rgba(0,0,0, 0.40);
                flex: 1;
                width: 100%;
                height: 100%;
                bottom: 0;
                justify-content: flex-end;
                border-radius: 20px;
            }

            .aboutus-intro-text {
                font-size: 36px;
                margin: 5px 0px;
                color: white;
                margin: 0px 20px;
                font-weight: 600;
            }

            .aboutus-job-title {
                font-size: 22.24px;
                margin: 0px 20px 20px;
                color: white;
            }
            .aboutus-values-main {
                margin-top: 50px;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                width: 80%;
            }
            .aboutus-values-row {
                width: 100%;
                display: flex;
                flex-direction: row;
                justify-content: space-around;
                align-items: flex-start;
                margin-bottom: 40px;
            }
            .aboutus-values-box {
                width: 35%;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
            }
            .aboutus-values-icon {
                border-radius: 50%;
                height: 150px;
                width: 150px;
                background-color: grey;
            }
            .aboutus-values-title {
                padding-top: 20px;
                text-align: center;
                font-size: 24px;
                font-weight: 600;
                padding-bottom: 10px;
            }
            .aboutus-values-text {
                text-align: center;
                font-size: 18px;
            }
            .aboutus-values-italic {
                font-style: italic;
            }
            
        `}</style>
        </>
}

export default AboutUs


const AboutUsMain = styled.div`
    display: flex;
    height: 600px;
    justify-content: space-between;
    align-items: center;
    @media (max-width: 1000px) {
        flex-direction: column;
        height: 900px;
    }
`