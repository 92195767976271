import React, {useState} from 'react'
import Footer from './Footer'
import Header from './Header'
import styled from 'styled-components'
import quokka from "../assets/quokka.png";
import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
import { HashLink } from "react-router-hash-link";

const AddYourVenue = () => {

    const [inputs, setInputs] = useState({});
    const [value, setValue] = useState();
    const [submitted, setSubmitted] = useState(false);

    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setInputs(values => ({...values, [name]: value}))
      }
    
    const submit = (e) => {
        e.preventDefault()
        setTimeout(() => {
            setSubmitted(true);
            setInputs({});
            setValue();
        }, 1000);
    }

    return (
        <>
        <Header />
        <div className='addyourvenue-main'>
            <div className='addyourvenue-box'>
                <div className='addyourvenue-left'>
                    <div className='addyourvenue-text'>
                        <div className='addyourvenue-title'>
                            Partner With Us!
                        </div>
                        <div className='addyourvenue-message'>
                            Do you like the sound of Pouch Loyalty and would like to be a part of our journey?
                            <br/><br/>
                            Let's grow together!
                        </div>
                    </div>
                    <div className='addyourvenue-image'>
                        <img src={quokka} style={{height: "315px", width: "236px"}} alt="Quokka Image"/>
                    </div>
                </div>
                
                <div className='addyourvenue-right'>
                    {!submitted && <>
                        <form
                            action="https://formsubmit.co/pouchloyalty@gmail.com" 
                            method="POST"
                            onSubmit={e => {submit(e)}}
                            className='addyourvenue-form'
                        >
                            <input type="hidden" name="_captcha" value="false" />
                            <input type="hidden" name="_next" value="http://localhost:3000/addyourvenue" />

                            <div className='addyourvenue-input-text'>
                                Business Name
                            </div>
                            <input 
                                type="text"
                                value={inputs.businessname || ""} 
                                onChange={handleChange}
                                name="businessname"
                                className='addyourvenue-small-input'
                                required
                            />

                            <div className='addyourvenue-input-text'>
                                Contact (Phone/Email)
                            </div>
                            <input 
                                type="contact"
                                value={inputs.contact || ""} 
                                onChange={handleChange}
                                name="contact"
                                className='addyourvenue-small-input'
                                required
                            />

                            <div className='addyourvenue-input-text'>
                                Business Type
                            </div>
                            <input
                                type="text"
                                value={inputs.type || ""} 
                                onChange={handleChange}
                                name="type"
                                className='addyourvenue-small-input'
                                required
                            />

                            <div className='addyourvenue-input-text'>
                                Location
                            </div>
                            <input
                                type="text"
                                value={inputs.location || ""} 
                                onChange={handleChange}
                                name="location"
                                className='addyourvenue-small-input'
                                required
                            />

                            <div className='addyourvenue-input-text'>
                                Additional Notes
                            </div>
                            <textarea
                                value={inputs.notes || ""} 
                                onChange={handleChange}
                                name="notes"
                                className='addyourvenue-large-input'
                            />
                            <input type="submit" value="Submit" className='addyourvenue-submit'/>
                        </form>
                    </>}

                    {submitted && <>
                        <div className='addyourvenue-submitted'>
                            Thank you for your interest in Pouch! We'll be in touch!
                        </div>
                    </>}
                </div>
            </div>
        </div>
        <Footer />

        <style jsx>{`
        .addyourvenue-main {
            margin-top: 100px;
            display: flex;
            width: 100%;
            justify-content: center;
            align-items: center;
            margin-bottom: 80px;
        }
        .addyourvenue-box {
            width: 75%;
            justify-content: space-between;
            align-items: center;
            display: flex;
            flex-direction: row;
        }
        .addyourvenue-left {
            display: flex;
            flex: 1;
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }
        .addyourvenue-text {
            flex-direction: column;
            align-items: flex-start;
        }
        .addyourvenue-title {
            font-size: 42px;
            font-weight: 600;
            background-image: linear-gradient(180deg, #F06B62, #FBB246);
            background-clip: text;
            -webkit-background-clip: text;
            color: transparent;
            margin-bottom: 40px;
        }
        .addyourvenue-message {
            font-size: 20px;
            padding-right: 70px;
        }
        .addyourvenue-image {
            margin-top: 77px;
            margin-right: 30px;
        }
        .addyourvenue-right {
            display: flex;
            flex: 1;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;
        }
        .addyourvenue-form {
            width: 100%;
        }
        .addyourvenue-input-text {
            font-size: 20px;
            font-weight: 600;
            margin-bottom: 5px;
        }
        .addyourvenue-small-input {
            width: 100%;
            height: 40px;
            font-size: 25px;
            background-color: #F5F5F5;
            border: 1px #A9A9A9 solid;
            border-radius: 10px;
            margin-bottom: 10px;
            padding-left: 10px;
        }
        .addyourvenue-large-input {
            width: 100%;
            height: 200px;
            font-size: 25px;
            background-color: #F5F5F5;
            border: 1px #A9A9A9 solid;
            border-radius: 10px;
            margin-bottom: 10px;
            padding-left: 10px;
            resize: none;
        }
        .addyourvenue-submit {
            width: 100%;
            height: 40px;
            font-size: 25px;
            font-weight: 600;
            background-image: linear-gradient(180deg, #F06B62, #FBB246);
            display: flex;
            border-radius: 10px;
            padding-left: 10px;
            border-width: 0px;
            align-items: center;
        }
        .addyourvenue-submitted {
            font-size: 42px;
            font-weight: 600;
        }
        

        `}</style>
        </>
    )
}

export default AddYourVenue