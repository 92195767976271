import Header from './Header.js';
import Footer from './Footer.js';
import Landing from './Landing.js';
import Definition from './Definition';
import SlideShow from './SlideShow';
import Partners from './Partners';
import Info from './Info';
import React, {useEffect} from 'react';
import styled from 'styled-components';

function Home() {
	return (
    	<>
		<div className='home-main'>
			<Header/>
			<div className='home-container'>
				<Landing/>
				<Definition/>
				<SlideBox> 
					<SlideShow/> 
				</SlideBox>
				<div className='home-bottom'>
					<div id="partners"> <Partners/> </div>
					<div id="info"> <Info/> </div>
				</div>
			</div>
			<Footer/>
		</div>
		<style jsx>{`
				.home-main {
					display: flex;
					width: 100vw;
					flex-direction: column;
					align-items: center;
					justify-content: center;
				}
				.home-container {
					display: flex;
					flex-direction: column;
				}
				.home-bottom {
					display: flex;
					flex-direction: column;
					justify-content: center;
					align-items: center;
				}
		`}</style>
		</>
  );
}

export default Home;


const SlideBox = styled.div`
	@media (max-width: 1200px) {
		display: none;
	}

`